<template>
  <v-container>
    <v-row class="mb-2">
      <v-col cols="12" md="6">
        <h1>{{ $vuetify.lang.t("$vuetify.testResult") }}</h1>
      </v-col>
      <v-col cols="12" md="6">
        <v-form @submit.prevent="options.search = searchForm">
          <v-text-field
            v-model="searchForm"
            @click:append="clearSearch"
            :append-icon="searchForm ? 'mdi-close' : ''"
            prepend-icon="mdi-magnify"
            :label="$vuetify.lang.t('$vuetify.search')"
            single-line
            hide-details
          >
          </v-text-field>
        </v-form>
      </v-col>
    </v-row>
    <data-table
      :headers="headers"
      :items="tests"
      :options.sync="options"
      :server-items-length="totalData"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:[`item.name`]="{ item: { name, isIrt, irtStatus } }">
        <span>{{ name }}</span
        ><v-chip
          v-if="isIrt"
          class="ml-2"
          :color="irtStatusColor(irtStatus)"
          text-color="white"
        >
          IRT
        </v-chip>
      </template>
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          first-icon="mdi-chevron-double-left"
          last-icon="mdi-chevron-double-right"
          prev-icon="mdi-chevron-left"
          next-icon="mdi-chevron-right"
          :items-per-page-options="[25, 50, 100, -1]"
          :options="options"
          :pagination="pagination"
          :show-first-last-page="true"
          :show-current-page="true"
        />
      </template>
      <template
        v-slot:[`item.action`]="{ item: { id, isIrt, irtStatus, irtFile } }"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              {{ $vuetify.lang.t("$vuetify.option") }}
              <v-icon right dark> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="calculateIrt(id)"
              :disabled="!isIrt || hasIrtProcessed(irtStatus)"
              link
            >
              <v-list-item-title>{{
                $vuetify.lang.t("$vuetify.calculateIrt")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="recalculateIrt(id)"
              :disabled="!['error', 'finished'].includes(irtStatus)"
              link
            >
              <v-list-item-title>{{
                $vuetify.lang.t("$vuetify.recalculateIrt")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="irtFile !== null && downloadFile(irtFile)"
              :disabled="irtFile === null"
              link
            >
              <v-list-item-title>{{
                $vuetify.lang.t("$vuetify.downloadIrtResult")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              :to="{
                name: 'Result.Test.GroupList',
                params: { test_id: id }
              }"
              link
            >
              <v-list-item-title>{{
                $vuetify.lang.t("$vuetify.groupList")
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </data-table>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import DataTable from "@/components/DataTable.js";
import Test from "@/services/test";

export default {
  name: "Result.Test.Index",
  data() {
    return {
      totalData: 0,
      tests: [],
      loading: true,
      options: {
        search: undefined
      },
      headers: [
        { text: "Test", value: "name" },
        { text: "Average Score", value: "averageScore" },
        { text: "Action", value: "action" }
      ],
      searchForm: null
    };
  },
  watch: {
    options: {
      handler() {
        this.getTests();
      },
      deep: true
    }
  },
  components: {
    DataTable
  },
  created() {
    this.setNavbarTitle(this.$vuetify.lang.t("$vuetify.testResult"));
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async getTests() {
      this.loading = true;
      this.tests = [];
      const tests = await Test.index({ ...this.options, sortBy: "name" });
      this.tests = tests.data.data.tests;
      this.totalData = tests.data.data.total_data;
      this.loading = false;
    },
    clearSearch() {
      this.options.search = undefined;
      this.searchForm = null;
    },
    async calculateIrt(id) {
      await Test.calculateIrt(id);
      this.$snackbar.showMessage({
        content: "IRT is queued and being generated",
        variant: "success"
      });
      await this.getTests();
    },
    async recalculateIrt(id) {
      await Test.recalculateIrt(id);
      this.$snackbar.showMessage({
        content: "IRT is queued and being generated",
        variant: "success"
      });
      await this.getTests();
    },
    irtStatusColor(status) {
      switch (status) {
        case "processing":
          return "warning";
        case "finished":
          return "green";
        case "error":
          return "red accent-2";
        default:
          return "primary";
      }
    },
    hasIrtProcessed(status) {
      return !(this.irtStatusColor(status) === "primary");
    },
    downloadFile(url) {
      const link = document.createElement("a");
      document.body.appendChild(link);
      link.href = url;
      link.click();
    }
  }
};
</script>

<style scoped>
.pointer:hover {
  cursor: pointer;
}
</style>
